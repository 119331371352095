
import store from "@/store";
import { Getter } from "@/store/helper";
import { School } from "@/entities/school";
import { Group } from "@/entities/group";
import { Classroom } from "@/entities/classroom";
import { Student, getGradeIndex } from "@/entities/student";
import { getThreadByThreadMaster, complementThreads } from "@/api/thread";
import { getThreadMasterByClassroomIds } from "@/api/thread_master";
import { ComplementedThread } from "@/entities/thread";
import { ThreadMaster } from "@/entities/thread_master";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MLoadingIcon from "@/components/MLoadingIcon.vue";
import MTable from "@/components/MTable.vue";
import MTableConditionItem from "@/components/MTableConditionItem.vue";
import MNavBar from "@/components/MNavBar.vue";
import MHeaderPanel, { Breadcrumb } from "@/components/MHeaderPanel.vue";
import MsSendModal from "@/components/student/MsSendModal.vue";
import SubmissionMasterDetailMultiRunDropDown from "@/components/submission/SubmissionMasterDetailMultiRunDropDown.vue";
import { Options, Vue } from "vue-class-component";
import { threadMasterDetailDefinitions } from "@/utils/table";
import {
  convertToDateFromUnixtimeSlash,
  convertToTimeFromUnixtime
} from "@/utils/date";
import SubmissionMasterAddModal from "@/components/submission/SubmissionMasterAddModal.vue";
import { State } from "@/store/helper";

type ThreadRow = {
  id: string;
  name: string;
  "suf-name": string;
  grade: string;
  "pre-grade": number;
  submittedAtDate: string;
  submittedAtTime: string;
  checkedAt: string;
  link: string;
  relatedLink: string;
  comment: string;
};

@Options({
  components: {
    MButton,
    MIcon,
    MLoadingIcon,
    MTable,
    MTableConditionItem,
    MNavBar,
    MHeaderPanel,
    MsSendModal,
    SubmissionMasterDetailMultiRunDropDown,
    SubmissionMasterAddModal
  }
})
export default class ThreadMasterList extends Vue {
  definitions = threadMasterDetailDefinitions;
  schoolId = "";
  complementedThreads: ComplementedThread[] = [];
  threadMaster: ThreadMaster | null = null;
  breadcrumbs: Breadcrumb[] = [
    {
      text: "提出BOX",
      link: "/submission_master"
    }
  ];
  selectedIds: string[] = [];
  selectedColumnList: number[] = [];
  isSendModalDisplayed = false;
  searchKeyword = "";
  initialized = false;

  @State("schools") schools!: School[];
  @State("classrooms") classrooms!: Classroom[];
  @State("students") students!: Student[];
  @State("groups") groups!: Group[];
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isTutorOfSchool") isTutorOfSchool!: boolean;

  get pageTitle() {
    return this.threadMaster?.data.name ?? "";
  }
  get filterIsSelected() {
    return false;
  }

  get columnList() {
    return this.definitions.map((value, index) => ({
      id: index,
      value: value.text
    }));
  }

  selectAllColumn() {
    this.selectedColumnList = [...Array(this.columnList.length)].map(
      (_, i) => i
    );
  }

  deselectAllColumn() {
    this.selectedColumnList = [];
  }

  get filteredDatas(): ThreadRow[] {
    let results: ThreadRow[] = this.complementedThreads.map(s => {
      const latestPost = s.data.posts
        .map(p => p)
        .filter(p => p.type !== "text")
        .sort((a, b) => b.time - a.time)[0];
      const submittedAtDate = latestPost?.time
        ? convertToDateFromUnixtimeSlash(latestPost?.time)
        : "--";
      const submittedAtTime =
        latestPost?.time > 0
          ? convertToTimeFromUnixtime(latestPost?.time)
          : "--";
      const preGrade = getGradeIndex(s.studentData?.grade ?? "");
      const studentsClassroom = s.studentRef?.parent.parent;
      const studentsSchool =
        studentsClassroom && studentsClassroom.parent.parent;
      const commentPost = s.data.posts.find(p => p.type === "text");
      return {
        id: s.ref.id,
        name: s.studentData?.name ?? "",
        "suf-name": `${this.schoolId}-${s.studentData?.id}`,
        grade: s.studentData?.grade,
        "pre-grade": preGrade,
        submittedAtDate,
        submittedAtTime,
        link: `/student/${studentsSchool?.id ?? ""}/${studentsClassroom?.id ??
          ""}/${s.studentRef?.id ?? ""}/submissions`,
        relatedLink: `/thread/print?id=${s.ref.id}`,
        comment: commentPost ? commentPost.value : "--"
      } as ThreadRow;
    });
    if (this.searchKeyword.length > 0) {
      results = results.filter(r =>
        r.name
          .toString()
          .toLowerCase()
          .includes(this.searchKeyword.toLowerCase())
      );
    }
    return results;
  }

  changeSelectedId(id: string) {
    if (this.selectedIds.includes(id)) {
      this.selectedIds = this.selectedIds.filter(item => item !== id);
    } else {
      this.selectedIds.push(id);
    }
  }

  get selectedStudentId(): string[] {
    return this.selectedIds
      .map(submissionId => {
        const matched = this.complementedThreads.find(
          _ => _.ref.id === submissionId
        );
        return matched?.studentRef?.id ?? "";
      })
      .filter(_ => _);
  }

  openAll() {
    window.open(`/thread/print?id=${this.selectedIds.join(",")}`, "_blank");
  }

  toggleSendModal(isDisplayed: boolean) {
    this.isSendModalDisplayed = isDisplayed;
  }

  async refresh() {
    if (store.state.loading) return;
    store.commit("START_LOADING", "更新中…");
    await this.getThreads();
    store.commit("END_LOADING");
  }

  async getThreads() {
    if (!this.threadMaster) return;
    const threads = await getThreadByThreadMaster(this.threadMaster.ref);
    const complemented = await complementThreads(threads);
    this.complementedThreads = complemented as ComplementedThread[];
  }

  async mounted() {
    this.schoolId = store.state.school?.data.id as string; // uid ではなく、ログイン時に使う数字
    try {
      // 対応する SubmissionMaster を取得
      const schoolDocId: string = store.state.school?.ref.id ?? "";
      if (!schoolDocId) throw new Error("school data not found"); // 通常ありえない
      const classroomIds = store.state.tutor?.config.data.classrooms.map(
        classroom => classroom.id
      ) as string[];
      const threadMasters = await getThreadMasterByClassroomIds(
        schoolDocId,
        classroomIds
      );
      const threadMasterId = this.$route.params.threadMasterId as string;
      const matchedThreadMaster = threadMasters.find(
        s => s.ref.id === threadMasterId
      );
      if (!matchedThreadMaster) {
        // classroom 単位の権限がない SubmissionMaster にアクセスしようとしている場合は除外される
        throw new Error("cannot access thread master");
      }
      this.threadMaster = matchedThreadMaster;
      this.getThreads();
      this.selectAllColumn();
    } catch (e) {
      alert(`提出物が取得できませんでした。\n${e.message}`);
    } finally {
      this.initialized = true;
    }
  }
}
